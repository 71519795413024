import axios from '../instances/axiosInstance';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';

// get user from localstorage, if possible
const user = window.localStorage.getItem('user')
  ? JSON.parse(window.localStorage.getItem('user'))
  : null;

export default {
  namespaced: true,
  state: {
    fieldErrors: [],
    generalError: '',
    success: '',
    loading: false,
    is404: false,
    modalOpen: false,
    user: user,
    userToken: Cookies.get('userToken') || null,
    unfinishedUser: null
  },
  getters: {
    errors: state => {
      return {
        general: state.generalError,
        field: state.fieldErrors
      }
    },
    success: state => state.success,
    loading: state => state.loading,
    is404: state => state.is404,
    modalOpen: state => state.modalOpen,
    userToken: state => state.userToken,
    user: state => state.user,
    unfinishedUser: state => state.unfinishedUser
  }, 
  mutations: {
    SET_SUCCESS(state, message) {
      state.success = message;
      state.generalError = '';
      state.fieldErrors = [];
      setTimeout(function() {
        state.success = '';
      }, 10000)
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    RESET_SUCCESS(state) { state.success = '' },
    SET_ERROR(state, payload) {
      console.log(payload);
      state.success = '';
      state.fieldErrors = [];
      state.loading = false;
      state.generalError = 'There was an error. Please try again.';
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    SET_ERRORS(state, payload) {
      state.loading = false;
      // console log
      console.log(payload);
      // if is AxiosError
      if (payload instanceof AxiosError) {
        // if network error, set general error
        if (payload.code == 'ERR_NETWORK') {
          state.generalError = 'There was a network error. Please try again.';
        }
        // otherwise, if field errors, set those
        else if (payload.response.data.field_errors) {
          state.generalError = '';
          state.fieldErrors = payload.response.data.field_errors;
        }
        // otherwise, if general error, set that
        else if (payload.response.data.message) {
          state.fieldErrors = [];
          state.generalError = payload.response.data.message;
        }
        // otherwise, fall back to default general error 
        else {
          state.fieldErrors = [];
          state.generalError = 'There was an error. Please try again.';
        }
      }
      // otherwise...
      else {
        // if it's just an object, check if it has a field_errors key
        if (payload.field_errors) {
          state.fieldErrors = payload.field_errors;
          state.generalError = '';
        } 
        // otherwise, check if it's a string and use that
        else if (typeof payload == 'String') {
          state.fieldErrors = [];
          state.generalError = payload;
        }
        // otherwise, fall back to default generic error
        else {
          state.fieldErrors = [];
          state.generalError = 'There was an error. Please try again.';
        }
      }
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    RESET_ERRORS(state) {
      state.fieldErrors = [];
      state.generalError = '';
    },
    START_LOADING(state) { state.loading = true },
    END_LOADING(state) { state.loading = false },
    TRIGGER_404(state) { state.is404 = true },
    CLEAR_404(state) { state.is404 = false },
    SET_MODAL_OPEN(state, modalOpen) { state.modalOpen = modalOpen },
    SET_UNFINISHED_USER(state, user) { state.unfinishedUser = user },
    SET_USER(state, user) {
      state.user = user;
      window.localStorage.setItem('user', JSON.stringify(user))
      window.location.reload();
    },
    UNSET_USER(state) {
      state.user = '';
      window.localStorage.removeItem('user');
      Cookies.remove('userToken', { path: '' });
      window.location.reload();
    }
  },
  actions: {
    setSuccess ({ commit }, success) { 
      commit('END_LOADING')
      commit('SET_SUCCESS', success) 
    },
    resetSuccess ({ commit }) { commit('RESET_SUCCESS') },
    setError ({ commit }, error) { 
      commit('END_LOADING')
      commit('SET_ERROR', error) 
    },
    setErrors ({ commit }, errors) {
      commit('END_LOADING') 
      commit('SET_ERRORS', errors) 
    },
    setErrorsWith404( {commit}, errors) {
      commit('END_LOADING') 
      if (errors instanceof AxiosError && errors.response && errors.response.status == 404) {
        commit('TRIGGER_404') 
      } else {
        commit('SET_ERRORS', errors) 
      }
    },
    resetErrors ({ commit }) { commit('RESET_ERRORS') },
    startLoading({ commit }) { 
      commit('RESET_ERRORS')
      // commit('RESET_SUCCESS')
      commit('START_LOADING') 
    },
    endLoading({ commit }) { commit('END_LOADING') },
    clear404({ commit }) {
      commit('CLEAR_404');
    },
    setModalOpen({ commit }, modalOpen) { 
      commit('SET_MODAL_OPEN', modalOpen) 
    },
    async getUnfinishedUser({ dispatch, commit }, id ) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.get(
          `unauthenticated/unfinished-user/${id}`
        )

        // end loading
        dispatch('app/endLoading', null, { root: true });

        console.log(request.data);

        // set user to starte
        commit('SET_UNFINISHED_USER', request.data)

        // return true
        return true;
      } catch(e) {
        // set special 404 error
        dispatch('app/setErrorsWith404', e, { root: true })

        // and return
        return false;
      }
    },
    // complete unfinished user
    async completeUnfinishedUser({ dispatch, state }, payload ) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(
          `unauthenticated/unfinished-user/${state.unfinishedUser._id}`,
          {
            password: payload.password,
            password__confirm: payload.password__confirm
          }
        );

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // set success
        dispatch('app/setSuccess', `Success. You may now log in, <strong>${state.unfinishedUser.name.full}</strong>.`, { root: true })

        // return true
        return true;
      } catch(e) {
        // set errors
        dispatch('app/setErrors', e, { root: true })

        // and return
        return false;
      }
    },
    // sign in
    async signIn({ commit, dispatch, state }, payload ) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(
          `unauthenticated/sign-in`,
          {
            email: payload.email,
            password: payload.password,
          }
        );

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // set success
        dispatch('app/setSuccess', `Success! You are logged in`, { root: true })
        console.log(request.data);

        // set user
        commit('SET_USER', request.data)

        // return true
        return true;
      } catch(e) {
        console.log(e);
        // set errors
        dispatch('app/setErrors', e, { root: true })

        // and return
        return false;
      }
    },
    // sign out
    async signOut({ commit, dispatch, state }, payload ) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(`unauthenticated/sign-out`);

        // end loading
        dispatch('app/endLoading', null, { root: true });
        console.log(request.data);

        // unset user
        commit('UNSET_USER')

        // return true
        return true;
      } catch(e) {
        console.log(e);
        // set errors
        dispatch('app/setErrors', e, { root: true })

        // and return
        return false;
      }
    }
  }
}