import McFilterBar from '@/components/McFilterBar';
import McDialogAlert from '@/components/McDialogAlert';
import Vue from 'vue';

const GlobalComponents = {
  install(Vue) {
    Vue.component(McFilterBar.name, McFilterBar)
    Vue.component(McDialogAlert.name, McDialogAlert)
  }
}

Vue.use(GlobalComponents);