import Vue from 'vue';

const mcFormatCurrency = {
  install(Vue, options) {
    Vue.prototype.mcFormatCurrency = (x) => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(x);
    }
  },
}

Vue.use(mcFormatCurrency)