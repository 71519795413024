import axios from 'axios';
import store from '@/store';

// create base url for every request
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT_2;

// send credentials automatically
axios.defaults.withCredentials = true;

// request interceptor
axios.interceptors.request.use(config => {
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.status == 401) {
    console.log('unsetting user');
    store.commit('app/UNSET_USER');
  }
  return Promise.reject(error);
});

// and export
export default axios;