import Vue from 'vue';

const mcFormatNumber = {
  install(Vue, options) {
    Vue.prototype.mcFormatNumber = (x) => {
      const formatter = new Intl.NumberFormat('en-US');
      return formatter.format(x);
    }
  },
}

Vue.use(mcFormatNumber)