import axios from '../instances/axiosInstance'

export default {
  namespaced: true,
  actions: {
    // get filter values
    async getFilterValues ({ dispatch }, payload) {
      try {
        // make request
        const request = await axios.get(
          'image-manager/filter-values',
          {
            params: {
              client: payload.client,
              filterType: payload.filterType,
              existingFilters: payload.existingFilters,
              search: payload.search
            }
          }
        );

        // and return data
        return request.data;
      } catch(e) {
        // return false
        return false;
      }
    },
    // start a batch
    async startBatch({ dispatch }, payload) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(
          'image-manager/start-batch',
          {
            client: payload.client,
            filters: payload.filters
          }
        );

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // set success
        dispatch('app/setSuccess', 'Success. This is being processed. Links will be sent to your email.', { root: true })

        // and return data
        return request.data;
      } catch(e) {
        // set error
        dispatch('app/setError', e, { root: true })

        // and return false
        return false;
      }
    },
    // get image assigns
    async getImageAssigns({ dispatch }, payload) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.get(
          'image-manager/image-assigns',
          {
            params: {
              client: payload.client,
              filters: payload.filters,
              pageNo: payload.pageNo
            }
          }
        );

        // end loading IF there are no more pages remaining
        if (request.data && request.data.pagesLeft == 0) {
          dispatch('app/endLoading', null, { root: true });
        }

        // and return data
        return request.data;
      } catch(e) {
        // set error
        dispatch('app/setError', e, { root: true })

        // and return false
        return false;
      }
    },
    // save changes
    async saveChanges({ dispatch }, payload) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(
          'image-manager/save-changes',
          {
            client: payload.client,
            images: payload.images
          }
        );

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // set success 
        dispatch('app/setSuccess', `Success. <strong>${request.data.count}</strong> ${request.data.count > 1 ? 'SKUs were' : 'SKU was'} updated.`, { root: true })

        // and return data
        return request.data.success;
      } catch(e) {
        // set error
        dispatch('app/setError', e, { root: true })

        // and return false
        return false;
      }
    }
  }
}