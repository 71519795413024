import axios from '../instances/axiosInstance'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

export default {
  namespaced: true,
  actions: {
    // get all users
    async get ({ dispatch }, payload ) {
      try {
        // set loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.get(
          'downloads',
          {
            params: {
              sorBy: payload.sortBy,
              sortByAsc: !payload.sortDesc,
              filters: payload.filters,
              pageNo: payload.pageNo,
            }
          }
        )

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // and return
        return request.data;
      } catch(e) {

        // set error
        dispatch('app/setError', e, { root: true })

        // and return
        return [];
      }
    },
    // get filters for users
    async getFilters ({ dispatch }, search) {
      try {
        // make request
        const response = await axios.get(
          `downloads/filter-suggestions`,
          {
            params: {
              search: search
            }
          }
        )

        // and return
        return response.data;
      } catch(e) {
        // set error
        dispatch('app/setError', e, { root: true })

        // and return
        return [];
      }
    },
    // download for amaznon
    async downloadForAmazon ({ dispatch }, payload) {
      try {
        // set loading
        dispatch('app/startLoading', null, { root: true });

        // for each image
        let folder = new JSZip();

        // iterate through each image
        for (const [index, image] of payload.images.entries()) {
          // download as blob
          const blob = await axios.get(
            image,
            { responseType: 'blob' }
          );

          // generate filename
          const fileName = /[^/]*$/.exec(image)[0];

          // add to folder
          folder.file(fileName, blob.data, { binary: true })
        }

        // generate zip
        await folder.generateAsync({
          type: 'blob'
        }).then(function(content) {
          saveAs(content, `${payload.batchId}_amazon.zip`);
        })

        // unset loading
        dispatch('app/endLoading', null, { root: true });

        // and return
        return true;
      } catch(e) {
        // set error
        dispatch('app/setError', e, { root: true })

        // and return
        return false;
      }
    }
  }
}