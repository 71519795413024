import Vue from 'vue';
const _ = require('lodash');

const ChangeData = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        emitChangeData(namespace, name, value) {
          this.$emit(`change-data-${namespace}`, { name: name, value: value })
        },
        changeData(payload) {
          _.set(this, payload.name, payload.value )
        }
      }
    })
  }
}

Vue.use(ChangeData);