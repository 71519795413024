import Vue from 'vue';

const DoMethod = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        emitDoMethod(namespace, name, value) {
          this.$emit(`do-method-${namespace}`, { name: name, value: value })
        },
        doMethod(payload) {
          eval(this[payload.name](payload.value || null))
        }
      }
    })
  }
}

Vue.use(DoMethod);