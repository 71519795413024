import Vue from 'vue'
import Vuex from 'vuex'
import appStore from './appStore.js';
import imageManagerStore from './imageManagerStore.js';
import masterDataStore from './masterDataStore.js';
import salesDataStore from './salesDataStore.js';
import usersStore from './usersStore.js';
import downloadsStore from './downloadsStore.js';

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    app: appStore,
    masterData: masterDataStore,
    salesData: salesDataStore,
    imageManager: imageManagerStore,
    users: usersStore,
    downloads: downloadsStore
  }
})
