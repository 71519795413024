<template>
  <div class="mc-filter-bar">
    <!-- active-filters -->
    <div 
    v-if="selectedFilters.length > 0" 
    class="mc-active-filters d-block mb-4"
    >
      <v-chip
        v-for="(filter, index) in selectedFilters"
        :key="index"
        @click:close="handleFilterDelete(index)"
        close
      >
        <strong>{{ filter.label }}:</strong>&nbsp;{{ filter.match }}
      </v-chip>
    </div> <!-- /active-filters -->
    <!-- autocomplete -->
    <v-autocomplete
      class="d-block"
      :search-input.sync="search"
      append-icon="mdi-magnify"
      :items="filters"
      filled
      :filter="filterSearch"
      @input="handleFilterInput"
      label="Search"
      hide-selected
      hide-no-data
      no-filter
      multiple
    >
      <!-- list-item-template -->
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-subtitle v-html="data.item.label"></v-list-item-subtitle>
          <v-list-item-title v-html="data.item.match"></v-list-item-title>
        </v-list-item-content>
      </template> <!-- /list-item-template -->
    </v-autocomplete> <!-- /autocomplete -->
  </div>
</template>

<script>
export default {
  name: 'McFilterBar',
  props: [
    'namespace',
    'filters', 
    'selectedFilters'
  ],
  data: () => ({
    search: ''
  }),
  watch: {
    search(val) {
      this.emitDoMethod(this.namespace, 'summonFilters', this.search);
    }
  },
  methods: {
    handleFilterInput (x) {
      console.log('filter input is')
      console.log(x);
      this.emitDoMethod(this.namespace, 'setSelectedFilters', x);
      this.search = '';
    },
    handleFilterDelete (index) {
      let filtersCopy = this.selectedFilters;
      filtersCopy.splice(index, 1);
      this.emitDoMethod(this.namespace, 'setSelectedFilters', filtersCopy);
    },
    filterSearch (item, queryText, itemText) {
      const field = item.field;
      const found = this.selectedFilters.some(x => x.field == field);
      return item.match.toLowerCase().indexOf(queryText.toLowerCase()) > -1 && !found;
    }
  }
}
</script>

<style lang="scss">
.mc-filter-bar .v-autocomplete .v-select__selection {
  display: none !important;
}
</style>