import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Parent from '@/views/Parent.vue'

// stores
import store from '@/store'

const defaultTitle = 'MŌLco Hub';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home/Home.vue')
  },
  {
    path: '/log-in',
    name: 'Log In',
    component: () => import('@/views/Unauthenticated/LogIn.vue')
  },
  {
    path: '/complete-user',
    component: Parent,
    children: [
      {
        path: ':id',
        name: 'Finish Registration',
        component: () => import('@/views/Unauthenticated/FinishRegistration.vue'),
        beforeEnter: (async (to, from, next) => {
          const id = to.params.id;
          await store.dispatch('app/getUnfinishedUser', id );
          next()
        }),
      }
    ]
  },
  {
    path: '/data',
    component: Parent,
    children: [
      {
        path: 'master',
        name: 'Master Data',
        component: () => import('@/views/Data/MasterData.vue'),
      },
      {
        path: 'sales',
        name: 'Sales Data',
        component: () => import('@/views/Data/SalesData.vue'),
      }
    ]
  },
  {
    path: '/image-manager',
    component: () => import('@/views/ImageManager/ImageManager.vue')
  },
  {
    path: '/reports',
    component: Parent,
    children: [
      {
        path: 'sales',
        name: 'Sales Reports',
        component: () => import('@/views/Reports/Sales.vue'),
      }
    ]
  },
  {
    path: '/users',
    component: Parent,
    children: [
      {
        path: '/',
        name: 'All Users',
        component: () => import('@/views/Users/AllUsers.vue'),
      },
      {
        path: 'new',
        name: 'New User',
        component: () => import('@/views/Users/NewUser.vue')
      },
      {
        path: ':id',
        name: 'Edit User',
        component: () => import('@/views/Users/EditUser.vue'),
        beforeEnter: (async (to, from, next) => {
          const id = to.params.id;
          await store.dispatch('users/getSingle', id );
          next()
        }),
      }

    ]
  },
  {
    path: '/downloads',
    component: () => import('@/views/Downloads/Downloads.vue')
  },
]

const router = new VueRouter({
  routes
})

// before navigation guard
router.beforeEach((to, from, next) => {
  // get user token from store to see if we are logged in
  const userToken = store.getters['app/userToken'];
  // clear 404, first of all
  store.dispatch('app/clear404' )

  // if the route is one of the unauthenticated routes, make sure we aren't logged in
  const unauthRoutes = ['Log In', 'Finish Registration', 'Forgot Password'];
  if (unauthRoutes.includes(to.name)) {
    // if user token is NOT set, proceed
    if (!userToken) {
      next()
    }
    // otherwise, go to home route
    else {
      next({
        name: 'Home'
      })
    }
  }
  // otherwise authenticate first
  else {
    // if user token is set, proceed
    if (userToken) {
      next();
    }
    // otherwise, go to log-in route
    else {
      next({
        name: 'Log In'
      })
    }
  }
})

// after navigation
router.afterEach((to, from) => {
  Vue.nextTick(() => {
      document.title = to.name 
        ? `${defaultTitle} — ${to.name}`
        : defaultTitle;
  });
});

export default router
