import axios from '../instances/axiosInstance'

export default {
  namespaced: true,
  actions: {
    // get upload jobs 
    async getUploadJobs({ dispatch }, showLoading = true) {
      try {
        // start loading
        if (showLoading)
          dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.get('master-data/upload-jobs');

        // end loading
        if (showLoading)
          dispatch('app/endLoading', null, { root: true });

        // and return data
        return request.data;
      } catch(e) {
        // set error
        if (showLoading)
          dispatch('app/setError', e, { root: true })

        // and return false
        return false;
      }
    },
    // upload new data
    async upload ({ dispatch }, file ) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // create formData object, for request
        const formData = new FormData();
        formData.append('file', file);

        // make request
        const request = await axios.post(
          'master-data/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        );

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // set success
        dispatch('app/setSuccess', 'The upload was successfully initiated. Be sure to check progress', { root: true })

        // and return data
        return request.data;
      } catch(e) {
        // set error
        dispatch('app/setError', e, { root: true })

        // and return false
        return false;
      }
    },
    // kill a job that's running
    async killJob ({ dispatch }, client ) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(
          'master-data/kill-job',
          {
            client: client
          }
        );

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // set success
        dispatch('app/setSuccess', 'The job was successfully killed', { root: true })

        // and return data
        return request.data;
      } catch(e) {
        // set error
        dispatch('app/setError', e, { root: true })

        // and return false
        return false;
      }
    },
  }
}