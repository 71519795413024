import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import globalComponents from './plugins/globalComponents'
import changeData from './plugins/changeData'
import doMethod from './plugins/doMethod'
import mcFormatCurrency from './plugins/mcFormatCurrency'
import mcFormatNumber from './plugins/mcFormatNumber'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VuetifyConfirm from 'vuetify-confirm'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.config.productionTip = false
Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VueMoment, {
  moment
})

new Vue({
  router,
  store,
  vuetify,
  globalComponents,
  mcFormatCurrency,
  mcFormatNumber,
  changeData,
  doMethod,
  render: h => h(App)
}).$mount('#app')
