<template>
  <!-- app -->
  <v-app>
    <!-- navigation drawer -->
    <v-navigation-drawer clipped app v-if="userToken">
      <v-list
        dense
        nav
      >
        <template v-for="item in nav">

          <!-- if item is a divider -->
          <v-divider v-if="item.type == 'divider'"></v-divider>

          <!-- if item has children -->
          <v-list-group
            v-else-if="item.items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.icon"
            :no-action="!item.to"
            :to="item.to"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.to"
              exact-path
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- if item has no children -->
          <v-list-item
            v-else
            :prepend-icon="item.icon"
            :to="item.to"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
        </template>
      </v-list>
    </v-navigation-drawer> <!-- /navigation drawer  -->

    <!-- app bar -->
    <v-app-bar
      class="app__bar"
      app
      clipped-left
      color="secondary"
      dark
    >
      <!-- logo -->
      <img class="app__logo" :src="logo"/> <!-- /logo -->
      <div class="flex-grow-1"></div>
      <!-- user-name -->
      <div class="app__user-name" v-if="user">
        <strong>{{ user.name.full }}</strong>
        <!-- actions-menu -->
        <v-menu offset-y min-width="200">

          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="signOut()">
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>  <!-- /actions-menu -->
      </div> <!-- user-name -->
    </v-app-bar> <!-- /app bar -->

    <!-- main -->
    <v-main>
      <!-- success alert -->
      <v-alert
        class="ma-4"
        @input="resetSuccess"
        :value="success ? true : false"
        border="left"
        type="success"
        close-text="OK"
        transition="scale-transition"
        dark
        dismissible
      >
        <div v-html="success"></div>
      </v-alert> <!-- /success alert -->
      <!-- error alert -->
      <v-alert
        class="ma-4"
        @input="resetErrors"
        :value="errors.general && !modalOpen ? true : false"
        border="left"
        type="error"
        close-text="OK"
        transition="scale-transition"
        dark
        dismissible
      >
        <div v-html="errors.general"></div>
      </v-alert> <!-- /error alert -->
      <!-- router view -->
      <div 
        class="app-404 pa-4"
        v-if="is404"
      >
        <h1 class="text-h1 mb-4">404</h1>
        <p class="text-h4">The resource you are looking for isn't here.</p>
      </div>
      <div 
        class="app-main pa-4"
        v-else
      >
        <div 
          class="app-loader"
          v-if="loading"
          transition="fade-transition"
        >
          <v-progress-circular
            class="app-loader__progress"
            :size="150"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <router-view/>
      </div> <!-- /router view -->
    </v-main> <!-- /main -->
  </v-app> <!-- /app -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import nav from '@/nav';
import Logo from '@/assets/images/molco-white.png';

export default {
  name: 'App',
  data: () => ({
    nav: nav,
    logo: Logo,
  }),
  computed: {
    ...mapGetters({
      errors: 'app/errors',
      success: 'app/success',
      loading: 'app/loading',
      is404: 'app/is404',
      modalOpen: 'app/modalOpen',
      user: 'app/user',
      userToken: 'app/userToken'
    })
  },
  methods:{
    ...mapActions({
      resetErrors: 'app/resetErrors',
      resetSuccess: 'app/resetSuccess',
      signOut: 'app/signOut'
    })
  }
};
</script>

<style lang="scss">
.app__logo {
  height: calc(100% - 20px);
}
.app__bar {
  z-index: 9999 !important;
}
.app-main {
  height: 100%;
  width: 100%;
  position: relative;
}
.app-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.75);

  &__progress {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
// .v-overlay--active {
//   z-index: 10000 !important;
// }
// .v-dialog__content--active {
//   z-index: 10001 !important;
// }
</style>