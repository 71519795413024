import axios from '../instances/axiosInstance'

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user: state => state.user
  },
  mutations: {
    SET_USER (state, user) { state.user = user },
  },
  actions: {
    // get all users
    async get ({ dispatch }, payload ) {
      try {
        // set loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.get(
          'users',
          {
            params: {
              sorBy: payload.sortBy,
              sortByAsc: !payload.sortDesc,
              filters: payload.filters,
              pageNo: payload.pageNo,
            }
          }
        )

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // and return
        return request.data;
      } catch(e) {

        // set error
        dispatch('app/setError', e, { root: true })

        // and return
        return [];
      }
    },
    // get filters for users
    async getFilters ({ dispatch }, search) {
      try {
        // make request
        const response = await axios.get(
          `users/filter-suggestions`,
          {
            params: {
              search: search
            }
          }
        )

        // and return
        return response.data;
      } catch(e) {
        // set error
        dispatch('app/setError', e, { root: true })

        // and return
        return [];
      }
    },
    // create user
    async create({ dispatch }, payload) {
      try {
        // set loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(
          'users',
          {
            name: payload.name,
            email: payload.email,
            type: payload.type
          }
        );

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // set success
        dispatch('app/setSuccess', 'The user was created', { root: true })

        // and return
        return true;
      } catch(e) {

        // set error
        dispatch('app/setErrors', e, { root: true })

        // return
        return false;
      }
    },
    // get single store
    async getSingle ({ commit, dispatch, state }, id ) {
      try {
        // start loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.get(
          `users/${id || state.user._id}`
        )

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // set user to starte
        commit('SET_USER', request.data)

        // return true
        return true;
      } catch(e) {
        // set special 404 error
        dispatch('app/setErrorsWith404', e, { root: true })

        // and return
        return false;
      }
    },
    // update single store
    async update({ dispatch, state }, payload) {
      try {
        // set loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(
          `users/${state.user._id}`,
          {
            name: payload.name,
            type: payload.type
          }
        );

        // set success
        dispatch('app/setSuccess', 'The user was updated', { root: true })

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // and return
        return true;
      } catch(e) {

        // set error
        dispatch('app/setErrors', e, { root: true })

        // return
        return false;
      }
    },
    // deactivate single user
    async deactivate({ dispatch }, id) {
      try {
        // set loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(`users/${id}/deactivate`);

        // set success
        dispatch('app/setSuccess', 'The user was deactivated', { root: true })

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // and return
        return true;
      } catch(e) {

        // set error
        dispatch('app/setErrors', e, { root: true })

        // return
        return false;
      }
    },
    // activate single user
    async activate({ dispatch }, id) {
      try {
        // set loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.post(`users/${id}/activate`);

        // set success
        dispatch('app/setSuccess', 'The user was activated', { root: true })

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // and return
        return true;
      } catch(e) {

        // set error
        dispatch('app/setErrors', e, { root: true })

        // return
        return false;
      }
    },
    // delete single user
    async delete({ dispatch }, id) {
      try {
        // set loading
        dispatch('app/startLoading', null, { root: true });

        // make request
        const request = await axios.delete(`users/${id}`);

        // set success
        dispatch('app/setSuccess', 'The user was deleted', { root: true })

        // end loading
        dispatch('app/endLoading', null, { root: true });

        // and return
        return true;
      } catch(e) {

        // set error
        dispatch('app/setErrors', e, { root: true })

        // return
        return false;
      }
    }
  }
}