<template class="pa-2">
  <!-- error alert -->
  <v-alert
    class="ma-4"
    @input="resetErrors"
    :value="errors.general ? true: false"
    border="left"
    type="error"
    close-text="OK"
    transition="scale-transition"
    dark
    dismissible
  >
    <div v-html="errors.general"></div>
  </v-alert> <!-- /error alert -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import nav from '@/nav';

export default {
  name: 'McDialogAlert',
  data: () => ({
    nav: nav
  }),
  computed: {
    ...mapGetters({
      errors: 'app/errors'
    })
  },
  methods:{
    ...mapActions({
      resetErrors: 'app/resetErrors'
    })
  }
};
</script>