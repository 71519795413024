// navigation items for

const nav = [
  {
    icon: 'mdi-home',
    title: 'Home',
    to: '/'
  },
  {
    icon: 'mdi-database',
    title: 'Data',
    items: [
      {
        title: 'Master Data',
        to: '/data/master'
      },
      {
        title: 'Sales Data',
        to: '/data/sales'
      }
    ]
  },
  {
    icon: 'mdi-folder-multiple-image',
    title: 'Image Manager',
    to: '/image-manager'
  },
  {
    icon: 'mdi-download-multiple',
    title: 'Downloads',
    to: '/downloads'
  },
  // {
  //   icon: 'mdi-chart-arc',
  //   title: 'Reports',
  //   items: [
  //     {
  //       title: 'Sales',
  //       to: '/reports/sales'
  //     }
  //   ]
  // },
  {
    type: 'divider'
  },
  {
    icon: 'mdi-account-group',
    title: 'Users',
    to: '/users'
  }
]

export default nav